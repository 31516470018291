import React, { useState } from "react";

import { Slider, Slide } from "./style";
import { FluidImageType } from "../../../utils/types";

const requestTimeout = (fn: () => void, delay: number) => {
  if (typeof window !== `undefined`) {
    if (!window.requestAnimationFrame) {
      return setTimeout(fn, delay);
    }

    const start = new Date().getTime();
    const handle = { value: 0 };

    const loop = () => {
      const current = new Date().getTime();
      const delta = current - start;

      (delta >= delay) ? fn() : window.requestAnimationFrame(loop);
    };

    handle.value = window.requestAnimationFrame(loop);

    return handle;
  }
};

interface BgImageSliderProps {
  images: FluidImageType[];
  delayMS?: number;
  imageFitStyle?: "contain" | "cover" | "fill" | "scale-down" | "none";
  imageFitStyleArray?: Array<"contain" | "cover" | "fill" | "scale-down" | "none">;
  current?: number;
  setCurrent?: (value: number) => void;
};

const BgImageSlider: React.FC<BgImageSliderProps> = ({ 
  images, 
  delayMS,
  imageFitStyle = "cover",
  imageFitStyleArray,
  current, 
  setCurrent 
}) => {
  const [index, setIndex] = useState<number>(0);

  const nOfImages = images.length - 1;

  const goToNextSlide = () => {
    if (current !== undefined && setCurrent) {
      if (current === nOfImages) {
        setCurrent(0);
      } else {
        setCurrent(current + 1);
      }
    } else if (index === nOfImages) {
      setIndex(0);
    } else {
      setIndex(index + 1);
    }
  };

  (nOfImages >= 1) && requestTimeout(goToNextSlide, delayMS || 5000);

  return (
    <Slider currentIndex={current || index}>
      {images.map((image, i) => (
        <Slide 
          key={i} 
          fluid={image.childImageSharp.fluid} 
          imageFitStyle={imageFitStyleArray 
            ? imageFitStyleArray[index] 
            : imageFitStyle
          }
        />
      ))}
    </Slider>
  );
};

export default BgImageSlider;